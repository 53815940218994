// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	outline: none;
	
	// transition: $card-transition;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
	&:hover {
		.card-image,
		.card-logo {
			opacity: 0.7;
		}
	}
}

// TAGGED CONTENT
.card-title-et {	
	h3 {
		font:$card-title-font;
	}
}

.card-display-date {
	font:$card-date-font;
	padding:0 0 12px; //12px 20px 10px;
}

.card-description-et {
	margin-bottom: 30px;
}

// /* MORE LINK */
.section-row:not(.view-type-slider),
.side-row //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:20px;
				margin:0 0 -4px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

.side-row .card-more-link {
	width:100%;
	margin-left: 0;
}


// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	height:48px;
	margin-top: 20px;
	a {
		height: 48px;
    padding: 11px 20px;
		border-radius: 30px;
		&:after {
			content: "";
			display: inline-block;
			width: 18px;
			height: 18px;
			margin-left: 10px;
			vertical-align: middle;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet {
	.card-text {
		display:block;
		font:normal 1.125em/1.22222222222222 $base-font-family;
	}
	.card-date {
		margin-top: 10px;
	}
}

// .card-logo {
// 	background:#FFFFFF;
// }
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

// .card-start-date {
// 	margin-right:2px;
// 	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
// 	text-align:center;
// 
// 	.card-date-day {
// 		font-size:1.75em;
// 		line-height:0.78571428571429;
// 	}
// 	.card-date-month {
// 		font-size:0.875em;
// 		line-height:1.22222222222222;
// 		text-transform:uppercase;
// 	}
// }

.event-date-wrapper {
	margin-bottom:10px;
	width: auto;
	display: inline-block;
	color:$primary;
	.date-d {
		font-size: 2.555em;
    display: inline-block;
		line-height: 120%;
	}
	.date-my {
    display: inline-block;
    font-size: 0.777em;
    width: 40px;
    line-height: 120%;
	}
}

.card-citation {
	font-style:italic;
}

.card-label {
	font-weight:bold;
}

.card-date-display-override,
.card-resource-type,
.card-content-type,
.card-published-by,
.card-date,
.card-location,
.card-type {
	font-size:0.777em;
	line-height:140%;
	display:inline-block;
	margin-bottom: 10px;
	width: 100%;
}

.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;

.view-tagged-content {
	.card-title-et h3 {
		font-family: $base-font-family;
		font-weight: normal;
	}
}

article.node-organisation {
	a.card,
	.card { 
		background: transparent!important;
	}
	.card-text {
		display: none;
	}
}