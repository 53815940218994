.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;  
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		margin-right: $horz-space;
		padding:20px;
		background:$card-medium-bg-color;
		&:hover {
			background:$card-hover-bg-color;
			.file-name {
				// text-decoration: underline;
				color:$star_command;
			}
		}
		.file-icon {
			margin-bottom: 12px;
			.file-extension {
				padding:9px 10px;
				font:normal 1.111em/140% $title-font-family;
				text-align:center;
				display: inline-block;
			}
			.file-size {
        padding: 10px 5px;
				font-size:1rem;			
				text-align:center;
				color:$tertiary;
				display: inline-block;
				// background: white;
				margin-left: -5px;
			}
			// /* FILE TYPE COLOURS */
		  
		  &.file-type-docx, &.file-type-doc {
		    .file-extension {
		      background:$docx_color;
		      color:white;
		    }
		  }
		  &.file-type-xlsx, &.file-type-xls {
		    .file-extension {
		      background:$xlsx_color;
		      color:white;
		    }
		  }
		  &.file-type-pptx, &.file-type-ppt {
		    .file-extension {
		      background:$pptx_color;
		      color:white;
		    }
		  }
		  &.file-type-pdf {
		    .file-extension {
		      background:$pdf_color;
		      color:white;
		    }
		  }
		  &.file-type-rtf, &.file-type-txt {
		    .file-extension {
		      background:$txt_color;
		      color:white;
		    }
		  }
		  &.file-type-jpg, &.file-type-png, &.file-type-bmp, gif {
		    .file-extension {
		      background:$jpg_color;
		      color:white;
		    }
		  }
		  &.file-type-zip, &.file-type-rar, &.file-type-7z {
		    .file-extension { 
		      background:$zip_color;
		      color:white;
		    }
		  }
		}
		
		.file-name {
			overflow:hidden;
			font:normal 1.222em/140% $title-font-family;
			// background: white;
			// padding: 20px;
			// box-shadow: 0px 2px 6px rgba(78, 78, 99, 0.08);
			// color: $primary;
			color:$primary;
			// margin:0 2px 2px;
		}
	}
} 

// PALETTE SPECIFIC 

.section-row	{
	&.palette-alt-2,
	&.palette-alt-3 {
		.attachment-link {
			background: $card-light-bg-color;
		}
	}
	&.palette-highlight {
		.attachment-link {
			background: $card-highlight-bg-color;
			&:hover {
				background:$card-hover-alt-bg-color;
			}
		}
	}
}

.side-row {
	.view-attachments .attachment-link {
		background: $card-light-bg-color;
		&:hover {
			background:$card-light-bg-color;
		}
	}
}

// RESPONSIVE STYLING

.view-attachments {
		.views-row {
			width:100%;
			float:left;
			
			@media (min-width:720px) {
				width:50%;
			}
			&:nth-child(2n+1){
				@media (min-width:720px) and (max-width:959px) {
					clear:left;
				}
			}
			
			@media (min-width:960px) {
				width:33.3%
			}
			&:nth-child(3n+1){
				@media (min-width:960px) and (max-width:1199px) {
					clear:left;
				}
			}
			
			@media (min-width:1200px) {
				width:25%			
			}
			&:nth-child(4n+1){
			 	@media (min-width:1200px) {
					clear:left;
				}
			}
		}
	}
	
.side-row {
	.view-attachments {
		.views-row {
			width:100%;
			.attachment-link {
				.file-icon {
					@media(min-width: 720px) and (max-width:960px) {
						right:calc(0% - 35%)
					}
				}
				
				.file-name {
					@media(min-width: 720px) and (max-width:960px) {
						text-align:center;
						overflow: visible;
						margin-top:60px;
						padding:20px 0 0;
					}
				}
			}
		}
	}
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments {
			.views-row {
				width:100%;
				
				@media (min-width:960px) {
					width:50%
				}
				&:nth-child(2n+1){
				 	@media (min-width:960px) {
						clear:left;
					}
				}
				&:nth-child(3n+1){
					@media (min-width:960px) {
						clear:none;
					}
				}
			}
		}
	}
}