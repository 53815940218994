.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	padding-top: 30px;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	li {
		vertical-align: middle;
		.pager-item {
			padding:0.5em;
		}
	}
	a {
		color: $star_command!important;
		font-family: $title-font-family;
		font-size: 1.111em;
		font-weight: 600;
		height:48px;
		width:48px;
		text-indent: -9999px;
		border-radius: 50%;
		
		&:hover {
			color:$bud_green!important;
		}
	}
	li.pager-next {
		padding:0;
		margin-left: 20px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			display: block;
			background: $arrow-blue-right no-repeat 50% 50% / 14px 20px;
			border:solid 2px $star_command;

			&:hover {
				display: block;
				background: $arrow-green-right no-repeat 50% 50% / 14px 20px;
				border:solid 2px $bud_green;
			}
		}
	}
	li.pager-last {
		//margin-left: -5px;
		padding:0;
		a {
			display: block;
			background: $arrow-blue-last no-repeat 50% 50% / 20px 25px;
			border:solid 2px $star_command;

			&:hover {
				display: block;
				background: $arrow-green-last no-repeat 50% 50% / 20px 25px;
				border:solid 2px $bud_green;
			}
		}
	}
	li.pager-previous {
		margin-right: 20px;
		padding: 0;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			display: block;
			background: $arrow-blue-left no-repeat 50% 50% / 14px 20px;
			border:solid 2px $star_command;

			&:hover {
				display: block;
				background: $arrow-green-left  no-repeat 50% 50% / 14px 20px;
				border:solid 2px $bud_green;
			}
		}
	}
	li.pager-first {
		//float: left;
		//margin-right: -5px;
		padding: 0;
		a {
			display: block;
			background: $arrow-blue-first  no-repeat 50% 50% / 20px 25px;
			border:solid 2px $star_command;

			&:hover {
				display: block;
				background: $arrow-green-first  no-repeat 50% 50% / 20px 25px;
				border:solid 2px $bud_green;
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		color:$primary;
		padding:0.25em 0.5em;
		font-family: $title-font-family;
		font-size: 1.111em;
		//margin-top: 0.25em;
	}
}

.section-row.palette-highlight {
	.item-list  ul.pager {
		a {
			color: $chrome_yellow!important;

			&:hover {
				color:$bud_green!important;
			}
		}
		li.pager-next {
			a {
				background: $arrow-white-right no-repeat 50% 50% / 14px 20px;
				border:solid 2px white;
				&:hover {
					background: $arrow-green-right no-repeat 50% 50% / 14px 20px;
					border:solid 2px $bud_green;
				}
			}
		}
		li.pager-last {
			a {
				background: $arrow-white-last no-repeat 50% 50% / 20px 25px;
				border:solid 2px white;
				&:hover {
					background: transparent $arrow-green-last no-repeat 50% 50% / 20px 25px;
						border:solid 2px $bud_green;
				}
			}
		}
		li.pager-previous {
			a {
				background: transparent $arrow-white-left no-repeat 50% 50% / 14px 20px;
				border:solid 2px white;
				&:hover {
					background: $arrow-green-left no-repeat 50% 50% / 14px 20px;
					border:solid 2px $bud_green;
				}
			}
		}
		li.pager-first {
			a {
				background: $arrow-white-first no-repeat 50% 50% / 20px 25px;
				border:solid 2px white;
				&:hover {
					background: transparent $arrow-green-first no-repeat 50% 50% / 20px 25px;
					border:solid 2px $bud_green;
				}
			}
		}
		
		li.pager-current {
			color:white;
		}
	}
}