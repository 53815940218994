// /* TAGLINES PALETTE */
// $tagline-font                  : 1.778rem/1.4 $title-font-family;
// $tagline-font-smaller          : 1.2rem/1.4 $title-font-family;

$tagline-bg-default            :white;
$tagline-text-bg-default       :rgba(231,239,242,0.9);
$tagline-color-default         :$primary;
$tagline-accent-default        :$circle-bg-green;

$tagline-bg-alt-1              :$morning_mist;
$tagline-text-bg-alt-1         :white;
$tagline-color-alt-1           :$primary;
$tagline-accent-alt-1          :$circle-bg-blue;

$tagline-bg-alt-2              :$honey_dew;
$tagline-text-bg-alt-2         :rgba(231,239,242,0.9);
$tagline-color-alt-2           :$primary;
$tagline-accent-alt-2          :$circle-bg-green;

$tagline-bg-alt-3              :white;
$tagline-text-bg-alt-3         :rgba(231,239,242,0.9);
$tagline-color-alt-3           :$primary;
$tagline-accent-alt-3          :$circle-bg-blue;

$tagline-bg-highlight          :$blue_indigo;
$tagline-text-bg-highlight     :$morning_mist;
$tagline-color-highlight       :$white;
$tagline-accent-highlight      :$circle-bg-white; 