// /* FILTERS  */ //

$form-label-font                 : normal 1.444em/130% $title-font-family;

$filter-bg-default               : $cultured;
$filter-text-colour-default      : $primary;
$input-bg-default                : $white;


// /* INPUTS  */ //
$fieldset-border-color           : $secondary;
$form-box-shadow                 : none;
$form-box-shadow-focus           : none; 
$form-border-color               : $secondary-border-color;
$form-border                     : 1px solid $secondary-border-color;
$form-border-radius              : 0;
$form-input-height               : 34px;
$form-select-arrow               : $arrow-filter no-repeat 100% 50% / 34px 34px;

// /* BUTTONS  */ //
$submit_bg                       : transparent;
$submit_border                   : solid 2px $chrome_yellow;
$submit_color                    : $davy_grey;
$submit_hover_bg                 : $chrome_yellow;
$submit_hover_color              : $davy_grey;

$reset_bg                        : transparent;
$reset_border                    : solid 2px $nickel;
$reset_color                     : $davy_grey;
$reset_hover_bg                  : $nickel;
$reset_hover_color               : $white;
