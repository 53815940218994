@import url(../fonts/fonts.css); 

$base-font-family      		: 'Frutiger LT W01_55 Roma1475738', sans-serif;
$title-font-family    		: 'Frutiger LT W01_57 Condensed', serif;
$subtitle-font-family 	 	: 'Frutiger LT W01_67 Bold Cond', serif;

$base-font-size						: 18px;
$base-line-height					: 140%;

$base-font-size-normal		: 18px;

$button-link-font 				: normal 1.111em/120% $subtitle-font-family;

/* FORMS */
$button-font 							: 600 1.111em/120% $subtitle-font-family;

/* FORMS */
$node-label-font 				  : 600 1.222em/140% $title-font-family;

/* FAQS */
$faq-question-font        : 1.444em/140% $title-font-family;