@mixin slick-controls-default() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background: $slick-default-prev-bg;
				border: $slick-default-border;
				&:hover {
					background: $slick-default-prev-hover-bg;
					border:$slick-default-border-hover;
				}
			}
			&.slick-next {
				background:$slick-default-next-bg;
				border:$slick-default-border;
				&:hover {
					background:$slick-default-next-hover-bg;
					border:$slick-default-border-hover;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-default-dot-border;
					&:hover {
						border:$slick-default-dot-hover-border;
						background: $slick-default-dot-hover-bg-color;
					}
				}
				&.slick-active button {
					background-color:$slick-default-dot-active-bg-color;
					border:$slick-default-dot-active-border;
					&:hover {
						background-color:$slick-default-dot-hover-bg-color;
						border:$slick-default-dot-hover-border;
					}
				}
			}
		}
	}

	.slick-controls { background: $slick-default-controls-bg; }
	.slick-nav { background: $slick-default-nav-bg; }
}

@mixin slick-controls-alt-1() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background:$slick-alt-1-prev-bg;
				border: $slick-alt-1-border;
				&:hover {
					background:$slick-alt-1-prev-hover-bg;
					border:$slick-alt-1-border-hover;
				}
			}
			&.slick-next {
				background:$slick-alt-1-next-bg;
				border:$slick-alt-1-border;
				&:hover {
					background:$slick-alt-1-next-hover-bg;
					border:$slick-alt-1-border-hover;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-alt-1-dot-default-border;
					&:hover {
						border:$slick-alt-1-dot-hover-border;
						background: $slick-alt-1-dot-hover-bg-color;
					}
				}
				&.slick-active button {
					background-color:$slick-alt-1-dot-active-bg-color;
					border:$slick-alt-1-dot-default-border;
					&:hover {
						background-color:$slick-alt-1-dot-hover-bg-color;
						border:$slick-alt-1-dot-hover-border;
					}
				}
			}
		}
	}
	.slick-controls { background: $slick-alt-1-controls-bg; }
	.slick-nav { background: $slick-alt-1-nav-bg; }
}


@mixin slick-controls-alt-2() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background:$slick-alt-2-prev-bg;
				&:hover {
					background:$slick-alt-2-prev-hover-bg;
				}
			}
			&.slick-next {
				background:$slick-alt-2-next-bg;
				&:hover {
					background:$slick-alt-2-next-hover-bg;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-alt-2-dot-default-border;
					&:hover {
						border:$slick-alt-2-dot-hover-border;
					}
				}
				&.slick-active button {
					background-color:$slick-alt-2-dot-active-bg-color;
					&:hover {
						background-color:$slick-alt-2-dot-active-hover-bg-color;
					}
				}
			}
		}
	}
	.slick-controls { background: $slick-alt-2-controls-bg; }
	.slick-nav { background: $slick-alt-2-nav-bg; }
}

// SLIDESHOW

@mixin slideshow-controls-default() {
	.view-id-slider .slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background: $slide-default-prev-bg;
				border: $slide-arrow-border;
				&:hover {
					background: $slide-default-prev-hover-bg;
					border: $slide-arrow-border-hover;
				}
			}
			&.slick-next {
				background:$slide-default-next-bg;
				border: $slide-arrow-border;
				&:hover {
					background:$slide-default-next-hover-bg;
					border: $slide-arrow-border-hover;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slide-default-dot-border;
					&:hover {
						border:$slide-default-dot-hover-border;
						background: $slide-default-dot-hover-bg-color;
					}
				}
				&.slick-active button {
					background-color:$slide-default-dot-active-bg-color;
					border:$slide-default-dot-active-border;
					&:hover {
						background-color:$slide-default-dot-hover-bg-color;
						border:$slide-default-dot-hover-border;
					}
				}
			}
		}
	}

	.slick-controls { background: $slide-default-controls-bg; }
	.slick-nav { background: $slide-default-nav-bg; }



}
