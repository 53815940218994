#sliding-popup .popup-content {
  .eu-cookie-compliance-categories,
  #popup-buttons {
    button {
      background: transparent;
      color: $chrome_yellow;
      border-radius: 25px;
      border: 2px solid $chrome_yellow;
      text-shadow: none;
      padding: 0.75em 1em;
      box-shadow: none;

      &:hover {
        background: $chrome_yellow;
        color: $eerie_black;
        border: 2px solid $chrome_yellow;
      }
    }
  }

  #popup-text {
    margin: 1rem 0 0;
    font-weight: 700;
    max-width: calc(100% - 60px);

    @include media($narrow) {
      max-width: calc(100% - 90px);
    }

    h2 {
      font: 600 1em/1.2 $title-font-family;
      margin-bottom: 10px;

      @include media($narrow) {
        font-size: 1.25em;
      }
    }

    p, .find-more-button {
      font: normal 0.875em/1.286 $base-font-family;
      vertical-align: baseline;
    }

    .find-more-button:hover {
      color: $platinum !important;
    }
  }

  .eu-cookie-compliance-categories label {
    font-weight: 500;
  }
}
