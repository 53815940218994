// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:100%;
	padding:0;
	position: relative;
	background:$gun_metal;
	@media(min-width: 720px) {
		background: transparent;
	}
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		border-radius: 50%;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:30px;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:30px;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;

	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 24px;
		border-radius: 50%;
	}

	.slick-dots {
		bottom:17px;
	}
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 5px;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 22px;
	}
	.slick-dots {
		bottom:-15px;
		@include media($narrow) {
		 bottom: -12px;
		}
		@media(min-width: $normal) {
			bottom:-100px;
		}
		@media(min-width: $wide) {
			bottom:-120px;
		}
	}
}






// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				//background-color:$slide-text-bg-color;

				@include media($narrow) {
					background-color: transparent;
				}

				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;
							//
							// @include media($narrow) {
							// 	// left: -10px;
							// 	// width: calc(100% + 20px);
							// }
							//
							// @include media($wide) {
							// 	// left:calc(50% - 800px);
							// 	// width: auto;
							// }
						}
					}

					// .card-description {
					// 	margin:-80px 10px 10px;
					// 	@include media($narrow) {
					// 		margin: 0
					// 	}
					// }

					.card-slide-description {
						bottom:0;
						position:relative;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding: 20px 20px 70px;
						margin:0;
						text-align: center;
						color:$slide-text-color;
						min-height:66px;
						// border-radius:30px;
						// border-radius: 140px;
						border-top:$slide-text-bg-border-mobile;
						// max-width: 550px;
						// left: calc(50% - 275px);

						p {
							font:$slide-text-font;
							margin: 0;
						}

						@include media($narrow) {
							width: 600px;
							padding: 30px 40px 60px;
							border-radius: 140px;
							position: absolute;
							bottom: 30px;
							margin: auto;
							left: calc(50% - 300px);
							border:$slide-text-bg-border-mobile;
						}
						@include media($normal) {
							background-color:$slide-text-bg-color;
							border-radius: 50%;
					    padding: 50px 20px;
					    width: 280px;
					    height: 280px;
					    top: calc(50% - 140px);
					    bottom: auto;
							left: 80px;
						}
						@include media($wide) {
							border:$slide-text-bg-border;
					    width: 380px;
					    height: 380px;
					    top: calc(50% - 190px);
							padding: 90px 40px;
						}
					}
				}
			}
		}

		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}

	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:40px;
		width:100%;
		left:0;
		@include media($narrow) {
			bottom:70px;
			width:400px;
			left:calc(50% - 200px);
		}
		@include media($normal) {
			width: 360px;
			left: 40px;
			top: calc(50% - 5px);
			bottom: auto;
		}
		@include media($wide) {
			width: 450px;
			left: 45px;
		}
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

// .front,
.not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;

		@media (min-width:1600px) {
			overflow: hidden;

			.view-slider .view-content .slick-list .slick-track .views-row {
				margin: 0;

				.card-image {
					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}

							@media (min-width:1600px) {
								left:0;
								width: 100%;
							}
						}
					}
				}
			}
		}

		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}
		// .view-footer {
		// 	left: calc(50% - 200px);
		// }
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;

			}
		}
	}
}

.one-sidebar,
.sidebar-second {
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
					background: none;
			    color: $primary;
			    text-align: left;
			    border-radius: 0;
			    border: none;
			    left: 0;
			    padding: 30px 20px 40px;
			    /* top: auto; */
			    height: auto;
				}
			}
			.view-footer {
				bottom: 20px;
				position:relative;
				width:100%;
				width: calc(100% - 100px);
    		left: 50px;
				.slick-nav .slick-dots {
			    bottom: 15px;

				}
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}

	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
    margin-top: $vert-space;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}

	}
	.view-footer {
		text-align:center;
	}
}

.card-slide-content-title {
	display: none;
}

.section-row.palette-highlight {
	.view-slider .view-content .slick-list .card-slide-description {
		background-color: #FFC973;
		color:$primary;
		border-color: #49516F;
	}
}

.block-views {
	.section-row,
	.side-row {
		.card-slide-description p a.button {
			margin-top:10px;
			font-size: 0.777em;
			padding: 5px 20px;
			color:#F7A600!important;
			&:hover {
				color:white!important;
			}
		}
		&.palette-highlight {
			.card-slide-description p a.button {
				border-color:#49516F;
				color:#49516F!important;
				&:hover {
					background: #49516F;
					border-color:#49516F;
					color: white!important;
				}
			}
		}
	}
}
