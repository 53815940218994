// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: $arrow-blue-left no-repeat scroll 50% 50% / 14px 20px;
$slick-default-prev-hover-bg      				: $arrow-green-left no-repeat scroll 50% 50% / 14px 20px;
$slick-default-next-bg            				: $arrow-blue-right no-repeat scroll 50% 50% / 14px 20px;
$slick-default-next-hover-bg      				: $arrow-green-right no-repeat scroll 50% 50% / 14px 20px;
$slick-default-border         			    	: 2px solid $star_command;
$slick-default-border-hover         			: 2px solid $bud_green;
$slick-default-dot-border         				: 1px solid $star_command;
$slick-default-dot-hover-border    				: 1px solid $bud_green;
$slick-default-dot-active-bg-color 				: $primary;
$slick-default-dot-active-border  				: 1px solid $primary;
$slick-default-dot-hover-bg-color       	: $bud_green;


$slick-alt-1-nav-bg                				: transparent; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: $arrow-white-left no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-prev-hover-bg         				: $arrow-green-left no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-next-bg               				: $arrow-white-right no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-next-hover-bg         				: $arrow-green-right no-repeat scroll 50% 50% / 14px 20px;
$slick-alt-1-border         			    	  : 2px solid $white;
$slick-alt-1-border-hover         			  : 2px solid $bud_green;

$slick-alt-1-dot-default-border    				: 1px solid white;
$slick-alt-1-dot-hover-border      				: 1px solid $bud_green;
$slick-alt-1-dot-active-bg-color   				: white;
$slick-alt-1-dot-hover-bg-color        		: $bud_green;


$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $faded_blue $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: $dark_lavender $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: $faded_blue $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: $dark_lavender $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: 2px solid $faded_blue;
$slick-alt-2-dot-hover-border      				: 2px solid $faded_blue;
$slick-alt-2-dot-active-bg-color   				: $faded_blue;
$slick-alt-2-dot-active-hover-bg-color 		: $faded_blue;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color         : $gun_metal;
$slide-text-bg-color-mobile  : rgba(39, 44, 60, 0.8);
$slide-text-bg-border        : solid 20px $chrome_yellow;
$slide-text-bg-border-mobile : solid 10px $chrome_yellow;
$slide-text-color            : $white;
$slide-text-font             : 1.444rem/130% $title-font-family;

$slide-arrow-bg              : rgba(73, 81, 111, 0.4);
$slide-arrow-border          : solid 2px $white;
$slide-arrow-border-hover    : solid 2px $chinese_yellow;

$slide-default-nav-bg              				: transparent; // used on slide and galler views
$slide-default-controls-bg         				: none; // used on twitter views etc
$slide-default-prev-bg             				: $slide-arrow-bg $arrow-white-left no-repeat scroll 50% 50% / 14px 20px;
$slide-default-prev-hover-bg      				: $slide-arrow-bg $arrow-yellow-left no-repeat scroll 50% 50% / 14px 20px;
$slide-default-next-bg            				: $slide-arrow-bg $arrow-white-right no-repeat scroll 50% 50% / 14px 20px;
$slide-default-next-hover-bg      				: $slide-arrow-bg $arrow-yellow-right no-repeat scroll 50% 50% / 14px 20px;
$slide-default-dot-border         				: 1px solid $white;
$slide-default-dot-hover-bg-color       	: $bud_green;
$slide-default-dot-hover-border    				: 1px solid $bud_green;
$slide-default-dot-active-bg-color 				: $white;
$slide-default-dot-active-border  				: 1px solid $white;