fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font: $form-label-font;
	font-weight: normal;
	margin-bottom: $vert-space/2;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: none;
	border-radius: $form-border-radius;
	// box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: 0.777em;
	margin-bottom: $vert-space/2;
	padding: 5px 50px 5px 10px;
	transition: border-color;
	width: 100%;
	height:$form-input-height;
	max-width: 370px;
	
	@media (min-width:0) and (max-width:719px) {
		max-width: 100%;
	}

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
	max-width: none;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background:$form-select-arrow;
	width: auto;
	border: none;
	border-radius: $form-border-radius;
	box-shadow: none;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: 0.777em;
	margin-bottom: $vert-space/2;
	padding: 5px 50px 5px 10px;
	transition: border-color;
	height:$form-input-height;
	-webkit-appearance: none;
	-moz-appearance: none;
	max-width: 370px;
	
	@media (min-width:0) and (max-width:719px) {
		max-width: 100%;
	}
}

.views-exposed-form {
	#{$all-text-inputs},
	select[multiple=multiple],
	textarea,
	select {
		max-width: 100%;
	}
}

.form-item-keywords input.form-text{
	&::-webkit-input-placeholder {
		color: $primary;
		font-size:0.777em;
		padding:3px 10px 6px;
		margin:0;
		margin-top:-5px;
	}
}

/* form specific */

form {
	.webform-progressbar {
		margin-bottom: $vert-space*2;
	}
	.form-item {
		margin-bottom:$vert-space;
		select {
			background-color: white;
		}
	}
	#edit-submitted-date-day,
	#edit-submitted-date-month,
	#edit-submitted-date-year,
	#edit-submitted-time-hour,
	#edit-submitted-time-minute {
		min-width: 120px;
		display: inline;
	}

	input[type="submit"].webform-previous {
		margin-right: 20px;
	}
}


/* login page */
#user-login {
	input[type="text"],
	input[type="password"] {
		background: $azureish_white;
		max-width: 600px;
	}
}


// CHECKBOXES AND RADIO //

.form-checkboxes,
.form-type-checkbox {
	input[type=checkbox] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	input[type=checkbox] + label.option {
		//color: $primary;
		display:inline-block;
		line-height:1.125rem; //0.9rem;
		background-repeat:no-repeat;
		// background-position: 0 3px; //1px;
		font-family: $base-font-family;
		font-size: 1rem;
		font-weight: 300;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		&:hover {
			//color: $primary;
		}
	}
	input[type=checkbox]:checked + label.option {
		background: $checkbox-active no-repeat scroll 0 3px / 13px 13px transparent;
		//color: $primary;
	}
	label.option {
		background: $checkbox-default no-repeat scroll 0 3px / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; 

		&:hover {
			background: $checkbox-hover no-repeat scroll 0 3px / 13px 13px transparent;
			//color:$primary;
		}
	}
	 
	.description {
		margin-left: 1.75em;
	}
}

 
.form-radio,
.form-type-radio { 
	input[type=radio] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	input[type=radio] + label.option {
		//color: $primary;
		display:inline-block;
		line-height:0.9rem;
		background-repeat:no-repeat;
		// background-position: 0 1px;
		font-family: $base-font-family;
		font-size: 1rem;
		font-weight: 300;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		&:hover {
			//color: $primary;
		}
	}
	input[type=radio]:checked + label.option {
		background: $radio-active no-repeat scroll 0 1px / 13px 13px transparent;
		//color: $primary;
	}
	label.option {
		background: $radio-default no-repeat scroll 0 1px / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			background: $radio-hover no-repeat scroll 0 1px / 13px 13px transparent;
			//color:$primary;
		}
	}
	
	.description {
		margin-left: 1.75em;
	}
}
 