.credit,
.vid-description {
	background: $white $ico-info-close no-repeat 100% 50% / 40px 40px;
	border: none;
	border-radius: 30px;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 1rem;
	color: $primary;
	padding: 8px 60px 7px 20px;
	position: absolute;
	right: 30px;
	top: 30px;
	text-align: right;
	
	&:hover {
		background: white $ico-info-close-hover no-repeat 100% 50% / 40px 40px;
	}
	
	&:empty {
		display: none;
	}
	

	&.collapsed {
		border-radius: 50%; 
		background: rgba(73, 81, 111, 0.4) $ico-info no-repeat 50% 50% / 6px 15px;
		height: 40px;
		width: 40px;
		margin-right: 0;
		text-align: inherit;
		text-indent: -9999px;
		padding:0;
		border:solid 2px white;
		&:hover {
			border:solid 2px $chrome_yellow;
		}
	}
}

// .front {
// 	.credit {
// 		right: 20px;
// 		top: 130px;
// 		@media (min-width: 610px) {
// 			right:100px;
// 		}
// 		@media (min-width:880px) {
// 			right: 20px;
// 		}
// 		@media (min-width:1200px) {
// 			right: 60px;
// 		}
// 		@media (min-width:1400px) {
// 			right: 100px;
// 		}
// 	}
// }