.section-row.section-with-banner {
  padding-top: 0;
  position: relative;
  padding-bottom: 0;
  background-color:$banner-default-bg!important;
  .section-inner {
    .view-banner {
      position: relative;
      @include outer-container($site-max-width);
        
      .card-banner {
        display: flex;
        align-items: center;
        flex-direction: column;
        
        @include media (540px) {
          min-height: 270px;
          width: 60%;
          flex-direction: row;
        }
        
        @include media ($narrow) {
          min-height: 380px;
          width: 50%;
        }
      
        @include media ($normal) {
          min-height: 580px;
        }
        
        @include media (1600px) {
          width: calc(100% - 860px);
        }
        
        .card-banner-image {
          background-size: calc(100% - 40px);
          background-position: 50% 40px;
          background-repeat: no-repeat;
          background-color: $azureish_white;
          order: 2;
          align-self: flex-end;
          width: 100%;
          padding: 0 20px;
          
          picture {
            position: relative;
            display: block;
          }
          
          @include media (540px) {
            position: absolute;  
            left: 60%;
            background-size: 305px 305px;        
            background-position: 0 70px;
            max-width: 340px;
            padding: 0;
          }
          
          @include media ($narrow) {
            background-size: 475px 475px;
            left: 50%;
            max-width: none;
          }
          
          @include media ($normal) {
            background-size: 770px 770px;
          }
          
          @include media (1600px) {
            left: auto;
            right: 0;
            width: auto;
          }
        }

        .banner-text-wrapper {
          margin: 0 auto;
          padding: 40px;
          order: 1;
          display: flex;
          justify-content: center;
          flex-flow: column wrap;
          height: 100%;
        
          p:last-child {
            margin-bottom: 0;
          }
        
          .banner-title {
            font: $banner-mobile-title-font;
            margin-bottom: 30px;
          }  
          .card-banner-description {
            font: $banner-mobile-summary-font;
          }
        
          @include media ($narrow) {
            .banner-title {
              font: $banner-narrow-title-font;
            }    
            .card-banner-description {
              font: $banner-narrow-summary-font;
            }
          }
        
          @include media ($normal) {
            .banner-title {
              font: $banner-title-font;
            }    
            .card-banner-description {
              font: $banner-summary-font;
            }
          }
        }
      }
    }
  }
}

//  BANNER STYLE SPECIFIC STYLES

.section-row.section-with-banner {
  .style_1 {
    background-color: $banner-bg-color-style-1;
    .banner-text-wrapper  {
      color:$banner-color-style-1;
    }
    .card-banner-image {
      //background-image: $banner-bg-image-style-1;
    }
  }
  .style_2 {
    background-color: $banner-bg-color-style-2;
    .banner-text-wrapper  {
      color:$banner-color-style-2;
    }
    .card-banner-image {
      //background-image: $banner-bg-image-style-2;
    }
  }
  .style_3 {
    background-color: $banner-bg-color-style-3;
    .banner-text-wrapper  {
      color:$banner-color-style-3;
    }
    .card-banner-image {
      //background-image: $banner-bg-image-style-3;
    }
  }
  .style_4 {
    background-color: $banner-bg-color-style-4;
    .banner-text-wrapper  {
      color:$banner-color-style-4;
    }
    .card-banner-image {
      background-color: $banner-bg-color-style-4;
      //background-image: $banner-bg-image-style-4;
    }
  }
  .style_5 {
    background-color: $banner-bg-color-style-5;
    .banner-text-wrapper  {
      color:$banner-color-style-5;
    }
    .card-banner-image {
      //background-image: $banner-bg-image-style-5;
    }
  }
}