.node-type-resource,
.node-type-publication {
	.card-authors,
	.card-abbr-authors {
		font-style:italic;
		margin-bottom:20px;
	} 
	/* side-row detail block */
	.view-display-id-details {
		.detail {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
			.card-label {
				display: inline-block;
				font-weight: 600;
				margin-bottom: 5px;
				width: 100%;
			}
		}
		.card-resource-type {
			font-size: 1em;
		}
	}
}