// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $subtitle-font-family;
$card-title-font        	: 1.333rem/140% $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: $card-title-font;
$card-feed-link-font			: 1.111rem/120% $subtitle-font-family;


$card-shadow                   : none;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
// $card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-font                : 0.8125em/1 $base-font-family;


/* --- HOVER STYLES  --- */

$card-hover-bg-color               : $morning_mist;
$card-hover-border                 : none;
$card-hover-shadow                 : $card-shadow;
$card-action-hover                 : $star_command;
$card-text-hover-color             : $secondary;
$card-date-hover-text-color        : $secondary;
$card-hover-subtitle-color         : $tertiary;
// $card-date-hover-bg-color          : $pale_grey;

$card-hover-alt-bg-color           : $white;
$card-hover-alt-border             : none;
$card-hover-alt-shadow             : $card-shadow;
$card-action-hover-alt             : $star_command;
$card-text-hover-alt-color         : $secondary;
$card-date-hover-alt-text-color    : $secondary;
$card-hover-alt-subtitle-color         : $tertiary;
// $card-date-alt-hover-bg-color      : $pale_grey;
/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color;
$card-text-with-summary-hover-bg    : $card-hover-bg-color;
$card-text-with-summary-title-color : white;
$card-text-with-summary-text-color  : white;


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $primary;
$card-light-action-default     : $primary;
$card-light-action-hover       : $action-hover;
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $tertiary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $honey_dew;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $primary;
$card-medium-action-default    : $primary;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $tertiary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $morning_mist;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $primary;
$card-highlight-action-default : $primary;
$card-highlight-action-hover   : $action-hover;
$card-highlight-text-color     : $secondary;
$card-highlight-subtitle-color : $tertiary;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $honey_dew;
$card-more-link-default-color       : $primary;
$card-more-link-default-icon        : $arrow-black-right no-repeat 100% 100% / 14px 20px;
$card-more-link-default-hover-bg    : $morning_mist;
$card-more-link-default-hover-color : $star_command;
$card-more-link-default-hover-icon  : $arrow-blue-right no-repeat 100% 100% / 14px 20px;

$card-more-link-alt-bg              : $morning_mist;
$card-more-link-alt-color           : $primary;
$card-more-link-alt-icon            : $arrow-black-right no-repeat 100% 100% / 14px 20px;
$card-more-link-alt-hover-bg        : $white;
$card-more-link-alt-hover-color     : $star_command;
$card-more-link-alt-hover-icon      : $arrow-blue-right no-repeat 100% 100% / 14px 20px;

$card-more-link-alt-2-bg            : $white;
$card-more-link-alt-2-color         : $primary;
$card-more-link-alt-2-icon          : $arrow-black-right no-repeat 100% 100% / 14px 20px;
$card-more-link-alt-2-hover-bg      : $white;
$card-more-link-alt-2-hover-color   : $star_command;
$card-more-link-alt-2-hover-icon    : $arrow-blue-right no-repeat 100% 100% / 14px 20px;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : white;
$card-feed-link-default-color       : $primary;
$card-feed-link-default-border      : solid 2px $chrome_yellow;
$card-feed-link-default-icon        : $arrow-black-right no-repeat scroll 50% 50% / 9px 14px;
$card-feed-link-default-hover-bg    : $chrome_yellow;
$card-feed-link-default-hover-color : $primary;
$card-feed-link-default-hover-icon  : $arrow-black-right no-repeat scroll 50% 50% / 9px 14px;

$card-feed-link-alt-bg              : $sandwashed;
$card-feed-link-alt-color           : $primary;
$card-feed-link-alt-icon            : $arrow-black-right no-repeat scroll 50% 50% / 9px 14px;
$card-feed-link-alt-hover-bg        : $chrome_yellow;
$card-feed-link-alt-hover-color     : $primary;
$card-feed-link-alt-hover-icon      : $arrow-black-right no-repeat scroll 50% 50% / 9px 14px;

$card-feed-link-alt-2-bg             : $morning_mist;
$card-feed-link-alt-2-color          : $primary;
$card-feed-link-alt-2-icon           : $arrow-black-right no-repeat scroll 50% 50% / 9px 14px;
$card-feed-link-alt-2-hover-bg       : $chrome_yellow;
$card-feed-link-alt-2-hover-color    : $primary;
$card-feed-link-alt-2-hover-icon     : $arrow-black-right no-repeat scroll 50% 50% / 9px 14px;

$card-feed-link-alt-3-bg             : $blue_indigo;
$card-feed-link-alt-3-color          : $chrome_yellow;
$card-feed-link-alt-3-icon           : $arrow-yellow-right no-repeat scroll 50% 50% / 9px 14px;
$card-feed-link-alt-3-hover-bg       : $chrome_yellow;
$card-feed-link-alt-3-hover-color    : $primary;
$card-feed-link-alt-3-hover-icon     : $arrow-black-right no-repeat scroll 50% 50% / 9px 14px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : transparent;
$button-link-default-border-color       : $chrome_yellow;
$button-link-default-color              : $secondary;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $chrome_yellow;
$button-link-default-hover-border-color : $chrome_yellow;
$button-link-default-hover-color        : $secondary;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $grey;
$button-link-alt-border-color           : $grey;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $action-hover;
$button-link-alt-hover-border-color     : $action-hover;
$button-link-alt-hover-color            : white;
$button-link-alt-hover-icon             : none;