// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
		@media (min-width:880px) {
			min-height:46px;
		}
		//
		// @include media(1150px) {
		// 	min-height:46px;
		// }
	}
	.main-nav-wrapper {
		@media (min-width:880px) {
			margin-top: 20px;
		}
	}
}

// MENU STYLES
#site-header {
	@include media(880px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	}
	.block-menu-block {
		clear:right;
		position: relative;

		.main-menu-btn {
			//background-color:$nav-button-bg-color;
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			// top:10px;
			margin-bottom: 0;
		//	margin: -($nav-button-height) 0px 0px 0px;

			.main-menu-btn-icon {
 				left:16px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;

				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}

				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			}
		}

		ul.sm {
			background: none;
			clear:both;
		}

		@include media(880px) {
			clear:right;
			float:right;
		}

		.menu-block-wrapper ul.menu.sm  {

			border:none;
			box-shadow:none;

			& > li {
				border-left:none;
				flex-grow: 1;
				text-align: center;

				&:last-child {
					position:relative;
				}

				@include media(880px) {
					& > a > .sub-arrow {
						display:none;
					}
				}

				& > a {
					background-color: $nav-base-bg-color;
					text-align: center;
					font:$nav-base-font;
					color:$nav-base-text-color;
					padding:$nav-base-padding;
					// text-transform: uppercase;

					@media (min-width:880px) and (max-width:1150px) {
						font: $nav-base-font-smaller;
						padding: $nav-base-padding-smaller;
					}

					&:hover {
						border-bottom: $nav-hover-border;
						padding:$nav-base-hover-padding;
						background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}
					&.current,
					&.active.current.highlighted {
						background-color:$nav-active-bg-color;
						color:$nav-active-text-color;
						border-radius:$nav-active-border-radius;
					}

					&.current.highlighted {
						background-color:$nav-highlight-current-bg-color;
						color:$nav-highlight-text-color;
						border-radius:$nav-active-border-radius;
					}

					&.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-bottom: $nav-highlight-border;
						border-top: none;
						padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}
					}
				}
			}

			li {
				a {
					cursor:pointer;
					background-color: transparent;

					.sub-arrow {
						@include sub-arrow-davy;
						background-size:contain;
						width: 16px;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}

				ul.menu {
					background:$nav-sub-bg-color;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					box-shadow:$nav-sub-box-shadow;

					li {
						border-top:none;

						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								// color:$nav-sub-hover-text-color;
								// text-decoration: underline;
							}

							.sub-arrow {
								@include sub-arrow-davy;
								// text-indent:-999em;
								// overflow:hidden;
								right:10px;
								// height:30px;
								background-size:9px 14px;
							}

							&.current,
							&.current:hover {
								background:$nav-sub-bg-color;
								color:$nav-active-trail-color;
								text-decoration: underline;
								.sub-arrow {
									@include sub-arrow-starcommand;
								}
							}

							&.active,
							&.active:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-active-trail-color;
								text-decoration: underline;
								@include menu-active;
								.sub-arrow {
									@include sub-arrow-starcommand;
								}
							}

							&:hover  {
								.sub-arrow {
									@include sub-arrow-davy;
								}
							}

							&.active:hover  {
								.sub-arrow {
									@include sub-arrow-starcommand;
								}
							}
						}
						&.expanded {

							& > a.highlighted {
								background-color:$morning_mist;
								// color:$nav-hover-text-color;

								.sub-arrow {
									@include sub-arrow-davy;
								}

								&:hover {
									background-color:$nav-sub-hover-bg-color;
									// color:$nav-sub-hover-text-color;
								}
								&.current {
									background:$nav-sub-bg-color;
									color:$nav-active-trail-color;
									text-decoration: underline;
									.sub-arrow {
										@include sub-arrow-starcommand;
									}
									&.active {
										// background-color:$nav-sub-hover-bg-color;
										color:$nav-active-trail-color;
										// text-decoration: underline;
										@include menu-active;
									}
								}
							}
						}
					}
				}
			}

		}

////////////////////////////////////      MOBILE      ////////////////////////////////////
		@media (max-width:879px) {

			#main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: -4px; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }


			.menu-block-wrapper ul.menu.sm {

				// border: $nav-mob-border;
				border-top: none;
				box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
				top:70px;
				position: absolute;
				right: 0;
				width: calc(100% - 5%);
				max-width: 400px;
				z-index: 1005;

				@media (min-width:$narrow) and (max-width:879px) {
					top: 0;
				}

				& > li {

					&.first.leaf {
						//border: none;
						//border-top:$nav-mob-expanded-border;
					}
					&.first.first {
						border: none;
					}

					a .sub-arrow {
						width: 34px;
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						background-color:$nav-mob-1st-bg-color;
						text-align: left;
						font:$nav-base-font;
						color:$nav-sub-text-color;
						padding:$nav-sub-base-padding;
						// text-transform: uppercase;

						&:hover {
							background-color:$chrome_yellow;
							color:$white;
							padding:$nav-sub-base-padding;

							.sub-arrow {
								@include sub-arrow-white-right;
							}
						}

						&.current {
							background:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;
							border-top:$nav-mob-expanded-border;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								background-color:$chrome_yellow;
								color:$white;
							}
						}

						&.active {
							background-color: $nav-mob-active-bg-color;
							color: $nav-mob-active-text-color;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						}

						&.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;

//							// LEVEL BORDERS: TOP  //
//							border-top: 1px solid $nav-mob-1st-border-color;

							.sub-arrow {
								@include sub-arrow-white-down;
							}
							&:hover {
								background-color:$chrome_yellow;
								color:$white;
								.sub-arrow {
									@include sub-arrow-white-down;
								}
							}
						}

						&.active-trail,
						&.current,
						&.active.current {
							background-color:$blue_indigo;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-highlight-border;
							padding:$nav-sub-base-hover-padding;

							&:hover {
								background-color:$chrome_yellow;
								color:white;
							}
							.sub-arrow {
								@include sub-arrow-white-right;
							}
						}

						&.active-trail.highlighted,
						&.current.highlighted,
						&.active.current.highlighted {
							background-color:$independence;

							.sub-arrow {
								@include sub-arrow-white-down;
							}
							&:hover {
								background-color:$chrome_yellow;
							}
						}

						&.active.highlighted {
							.sub-arrow {
								@include sub-arrow-white-down;
							}
						}
					}
				}

				li {
					border: none;

					a {
						// border-top: $nav-mob-border;
						border-left: none;
						// padding-left: 0.875rem;

						color: $nav-mob-text-color;
						.sub-arrow {
							@include sub-arrow-white-right;
							// background:$arrow-black-down no-repeat scroll center center transparent;
							// background-size:50%;
							// height: 46px;
							// margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}


					///////////////////////////////      NEXT LEVELS      ///////////////////////////////

					/* ul BACKGROUNDS */
					&.expanded {

						// 2nd level //
						ul.menu,
						ul.menu a.current {
							background-color: $nav-mob-2nd-bg-color;
							border-top: 2px solid $nav-mob-1st-border-color;

							li.first a {
								border-top:none;
							}

							li:not(.first) a {
								border-top: 1px solid $nav-mob-2nd-line-color;
							}
							&.active-trail {
								&:hover {
									background: $chrome_yellow;
									color:white;
									.sub-arrow {
										@include sub-arrow-white-right
									}
								}
							}

//							// LEVEL BORDERS: BOTTOM (TOP: Look 2nd level a.highlighted)  //
//							li.last {
//								border-bottom: 1px solid $nav-mob-1st-border-color;
//								margin-bottom: -1px;
//								z-index: 1;
//								position: relative;
//							}

							a {
								border-left: none;
								padding-left: 1.375rem;

								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $nav-mob-active-text-color;
								}
							}

							li.expanded {

								// 3rd level //
								ul.menu {
									background-color: $nav-mob-3rd-bg-color;
									border-top: 2px solid $nav-mob-2nd-border-color;

									li.first a {
										border-top:none;
									}

									li:not(.first) a {
										border-top: 1px solid $nav-mob-3rd-line-color;
									}

//									// LEVEL BORDERS: BOTTOM (TOP: Look 3rd level a.highlighted)  //
//									li.last {
//										border-bottom: 1px solid $nav-mob-2nd-border-color;
//										z-index: 2;
//									}

									a {
										border-left: none;
										padding-left: 1.875rem;

										&.active {
											background-color: $nav-mob-active-bg-color;
											color: $nav-mob-active-text-color;
										}
									}
								}

								li.expanded {

									// 4th level //
									ul.menu {
										background-color: $nav-mob-4th-bg-color;
										border-top: 2px solid $nav-mob-3rd-border-color;

										li.first a {
											border-top:none;
										}

										li:not(.first) a {
											border-top: 1px solid $nav-mob-4th-line-color;
										}

//										// LEVEL BORDERS: BOTTOM (TOP: Look 4th level a.highlighted)  //
//										li.last {
//											border-bottom: 1px solid $nav-mob-3rd-border-color;
//											z-index: 3;
//										}

										a {
											border-left: none;
											padding-left: 2.375rem;

											&.active {
												// background-color: $nav-mob-active-bg-color;
												color: $nav-mob-active-text-color;
												&:before {
													left:26px;
												}
											}
										}
									}
								}
							}
						}
					}

					/* ARROWS + HIGHLIGHT */
					ul.menu {
						box-shadow: none;

						li {
							//border-top:$nav-mob-expanded-border;
							border-top: none;

							a {
								color: $nav-sub-text-color ;
								.sub-arrow {
									@include sub-arrow-davy;
									// right:0;
									// height: 39px;
									// margin-top: -19px;
									//border-left: $nav-mob-border;
								}

								&.active {
									.sub-arrow {
										background:$arrow-black-down no-repeat scroll center center transparent;
										background-size:50%;
									}
								}

								&:hover,
								&.active:hover  {
									background: $chrome_yellow;
									color:white;
									.sub-arrow {
										@include sub-arrow-white-right
									}
								}
							}

							&.expanded {
								ul.menu a.current {
									background-color: $nav-mob-2nd-highlight-bg-color;

									&.active {
										background-color:$cultured;
										color:$nav-mob-active-text-color;
										&:before {
											left: 18px;
										}
										.sub-arrow {
											@include sub-arrow-starcommand;
										}
										&.active-trail {
											&:hover {
												background: $chrome_yellow;
												color:white;
												&:before {
													border-color: white;
												}
												.sub-arrow {
													@include sub-arrow-white-right
												}
											}
										}
									}

									&:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}

								////////////**     2nd LEVEL     **////////////
								& > a.highlighted {
									background-color:$nav-mob-2nd-highlight-bg-color;
									color:$nav-mob-2nd-highlight-text-color;

//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-2nd-border-color;

									.sub-arrow {
										@include sub-arrow-davy-down;
									}

									&:hover {
										background-color:$chrome_yellow;
										color:$white;

										.sub-arrow {
											@include sub-arrow-white-down;
										}
									}
									&.current.active {
										background-color:$middle_green;
										color:$nav-hover-text-color;

										.sub-arrow {
											@include sub-arrow-white-down;
										}
									}
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded > a.highlighted {
									background-color: $nav-mob-3rd-highlight-bg-color;
									color: $nav-mob-3rd-highlight-text-color;

//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-3rd-border-color;

									.sub-arrow {
										@include sub-arrow-davy-down;
									}
									&.active-trail {
										color:$nav-mob-active-text-color;
										.sub-arrow {
											@include sub-arrow-starcommand-down;
										}
									}
									// &:hover,
									// &.current.active {
									// 	background-color:$nav-sub-hover-bg-color;
									// 	color:$nav-hover-text-color;
									//
									// 	.sub-arrow {
									// 		@include sub-arrow-white-down;
									// 	}
									// }
								}

								////////////**     4th LEVEL     **////////////
								& > ul.menu > li.expanded > ul.menu > li.expanded > a.highlighted {
									background-color: $nav-mob-4th-highlight-bg-color;
									color: $nav-mob-4th-highlight-text-color;

//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-4th-border-color;

									.sub-arrow {
										background:$arrow-black-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover,
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											@include sub-arrow-white-down;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
