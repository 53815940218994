// /* HEADER */ //

$header-menu-font 				: /*0.875rem*/ 0.778rem/1 $base-font-family;
$header-search-field-font : 0.875rem/1.286 $base-font-family;

$header-bg-color                   : $white;

$header-menu-link-color            : $white;
$header-menu-link-hover            : $white;

$header-search-field-bg-color      : $pale_grey;
$header-search-field-text-color    : $primary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;


$search-toggle-width-mobile        : 70px;
$search-toggle-height-mobile       : 70px;
$search-toggle-width-normal        : 64px;
$search-toggle-height-normal       : 46px;
$search-toggle-width-wide          : 80px;
$search-toggle-height-wide         : 46px;

$search-icon-size-mobile           : 40px 40px;
$search-icon-size-normal           : 20px 20px;
$search-icon-size-wide             : 20px 20px;

$search-toggle-bg-mobile           : $white;
$search-toggle-bg                  : $bud_green;
$search-toggle-bg-hover            : $chrome_yellow;
$search-toggle-bg-active           : $middle_green;

$search-icon-mobile: $search-indigo no-repeat 50% 50% $search-toggle-bg-mobile;
$search-icon: $search-white no-repeat 50% 50% $search-toggle-bg;
$search-icon-hover: $search-white no-repeat 50% 50% $search-toggle-bg-hover;
$search-icon-active: $search-close no-repeat 50% 50% $search-toggle-bg-active;
$search-icon-active-hover: $search-close no-repeat 50% 50% $search-toggle-bg-hover;
$search-icon-input: $search-green no-repeat 50% 50%/ 20px 20px transparent;
