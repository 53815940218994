// /* MAIN MENU */ //

$nav-base-font        	 	: bold 1.222rem/120% $subtitle-font-family;
$nav-base-font-smaller 		: bold 1.222rem/120% $subtitle-font-family;
$nav-sub-base-font        : 1rem/140% $base-font-family;

$nav-wrapper-bg-color			    		: $bud_green;
$nav-base-bg-color            		: white;
$nav-base-text-color     	    	 	: $white;
$nav-base-padding        	    		: 10px 30px;
$nav-base-hover-padding   	    	: 10px 30px;
$nav-sub-base-padding					    : 10px 20px;
$nav-sub-base-hover-padding		    : 10px 20px;

$nav-base-padding-smaller 				: 10px 20px;
$nav-base-hover-padding-smaller 	: 10px 20px;

$nav-active-bg-color          		: $independence;
$nav-active-text-color    	    	: $white;
$nav-active-border-radius 	    	: 0;
$nav-active-trail-color           : $star_command;

$nav-hover-bg-color       	    	: $chrome_yellow;
$nav-hover-text-color     	    	: $white;
$nav-hover-border	  	    	    	: none;

$nav-highlight-bg-color   	    	: $middle_green;
$nav-highlight-current-bg-color   : $independence;
$nav-highlight-text-color 	    	: $white;
$nav-highlight-border	    	    	: none;

$nav-sub-bg-color         	    	: $white;
$nav-sub-hover-bg-color   	    	: $cultured;
$nav-sub-text-color       	    	: $secondary;
$nav-sub-hover-text-color       	: $secondary;
$nav-sub-box-shadow      		    	: $box-shadow;
$nav-sub-border          		    	: none;
$nav-sub-border-radius  		      : none;

$nav-mob-text-color      						: white;
$nav-mob-active-text-color      		: $star_command;
$nav-mob-active-bg-color      			: $blue_indigo;

$nav-mob-1st-bg-color     					: $bud_green;
$nav-mob-1st-highlight-bg-color     : $middle_green;
$nav-mob-1st-highlight-text-color   : $white;
$nav-mob-1st-line-color     				: none;
$nav-mob-1st-border-color     			: none;

$nav-mob-2nd-bg-color     					: $white;
$nav-mob-2nd-highlight-bg-color     : $morning_mist;
$nav-mob-2nd-highlight-text-color   : $secondary;
$nav-mob-2nd-line-color     				: none;
$nav-mob-2nd-border-color     			: none;

$nav-mob-3rd-bg-color     					: $cultured;
$nav-mob-3rd-highlight-bg-color     : $morning_mist;
$nav-mob-3rd-highlight-text-color   : $secondary;
$nav-mob-3rd-line-color     				: none;
$nav-mob-3rd-border-color     			: none;

$nav-mob-4th-bg-color     					: $white;
$nav-mob-4th-highlight-bg-color     : $morning_mist;
$nav-mob-4th-highlight-text-color   : $secondary;
$nav-mob-4th-line-color     				: none;
$nav-mob-4th-border-color     			: none;

$nav-mob-border											: 1px solid $battleship_grey;
$nav-mob-expanded-border						: 1px solid $battleship_grey;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;

/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $white;
$nav-button-hover-bg-color			: $middle_green;
$nav-button-height      				: 70px;
$nav-button-width      					: 78px;
$nav-button-bar-bg-color      	: $blue_indigo;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 48px;
$nav-button-bar-border-radius 	: 8px;

@mixin menu-active() {
  position: relative;
	&:before {
    display: inline-block;
    content:"";
    height: 8px;
    width: 8px;
    background: transparent;
    border:2px solid $star_command;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 2px);
    left: 7px;
  }
}

@mixin sub-arrow-davy() {
  background:$arrow-davy-right no-repeat scroll center center / 14px 20px transparent;
}
@mixin sub-arrow-davy-down() {
  background:$arrow-davy-right no-repeat scroll center center / 14px 20px transparent;
  transform: rotate(90deg);
}
@mixin sub-arrow-starcommand-down() {
  background:$arrow-blue-right no-repeat scroll center center / 14px 20px transparent;
  transform: rotate(90deg);
}
@mixin sub-arrow-starcommand() {
  background:$arrow-blue-right no-repeat scroll center center / 14px 20px transparent;
}
@mixin sub-arrow-white-right() {
  background:$arrow-white-right no-repeat scroll center center / 14px 20px transparent;
  right:10px;
}
@mixin sub-arrow-white-down() {
  background:$arrow-white-right no-repeat scroll center center / 14px 20px transparent;
  transform: rotate(90deg);
  right:10px;
}
