.card-tagline-text {
  // font:$tagline-font;
  // max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  z-index: 5;
  @include media($narrow) {
    padding: 50px;
  }
  p:last-child {
    margin-bottom: 0; 
  }
}

// PALETTE SPECIFIC
​
.content-main,
.content-additional {
  .section-row.section-with-tagline {
    // background:$tagline-bg-default;
    .section-inner {
      margin:50px auto;
      .card-tagline-text  {
        background:$tagline-text-bg-default;
        color:$tagline-color-default;
      }
    }
  }
}
.content-main {
  .section-row.section-with-tagline {
    .section-inner {
      margin:50px auto;
    }
  }
}

.section-row.section-with-tagline {
  padding: 60px 0 ;
  // @include media($narrow) {
  //   padding: 60px 0;
  // }
  .section-inner {
    position: relative;
    overflow: visible;
    max-width: 90%;
    z-index: 0;
    margin:0 auto;
    @include media($narrow) {
      max-width: 80%;
    }
    &:after {
      position: absolute;
      display: inline-block;
      height:175px;
      width:160px;
      content:"";
      background: no-repeat 50% 50% / 160px 175px;
      top:-30px;
      left: -15px;
      z-index: -1;
      @include media($narrow) {
        left: -90px;
        top:-30px;
      }
    }
  }
  &.palette-default {
    background:$tagline-bg-default;
    .card-tagline-text  {
      background:$tagline-text-bg-default;
      color:$tagline-color-default;
    }
    .section-inner {
      &:after {
        background-image:$tagline-accent-default;
      }
    }
  }
  &.palette-alt-1 {
    background:$tagline-bg-alt-1;
    .card-tagline-text  {
      background:$tagline-text-bg-alt-1;
      color:$tagline-color-alt-1;
    }
    .section-inner {
      &:after {
        background-image:$tagline-accent-alt-1;
      }
    }
  }
  &.palette-alt-2 {
    background:$tagline-bg-alt-2;
    .card-tagline-text  {
      background:$tagline-text-bg-alt-2;
      color:$tagline-color-alt-2;
    }
    .section-inner {
      &:after {
        background-image:$tagline-accent-alt-2;
      }
    }
  }
  &.palette-alt-3 {
    background:$tagline-text-bg-alt-3;
    .card-tagline-text  {
      background:$tagline-bg-alt-3;
      color:$tagline-color-alt-3;
    }
    .section-inner {
      &:after {
        background-image:$tagline-accent-alt-3;
      }
    }
  }
  &.palette-highlight {
    background:$tagline-text-bg-highlight;
    .card-tagline-text  {
      background:$tagline-bg-highlight;
      color:$tagline-color-highlight;
      p {
        color:$tagline-color-highlight;
      }
    }
    .section-inner {
      &:after {
        background-image:$tagline-accent-highlight;
      }
    }
  }
}