@media (min-width:$wide) {
	.no-sidebars .node-content .card-summary,
	.no-sidebars .node-content .card-intro,
	.no-sidebars .node-content .card-body,
	.no-sidebars .node-content .card-authors {
		padding:0 16% 0 0;
	}
}
.node-leaf {
  main {
    background-color: $main-page-bg;
  }
  #content {
    padding: $vert-space;
    background-color: white;
		overflow:hidden;
    
    .card-title-field h1 {
      font-size: 2rem;
      font-weight: normal;
    }
    .card-intro {
      margin-top: $vert-space;
    }
    .card-image {
      float: left;
      margin: 0 $vert-space $horz-space 0;
      
      @media(min-width:640px){
        max-width:55%;
      }
    }
  }
}

main section#content {
  .content-main,
  .content-additional {
    padding: 0;
    @include media(880px) {
      padding: 0 20px 0 0;
    }
  }
	.content-main + .content-additional {
		margin-top: $vert-space*1.5;
	}
}

.node-content {
	h1 .card-title-field {
		font-size:2rem;
		line-height:1.1875;
		color:$primary;
		font-family:$title-font-family;
		margin:0 0 0;
		text-transform:none;
	}
	
	.card-image {
		margin-bottom:$vert-space;
	}

	.card-date-display-override,
	.card-display-date,
	.card-date {
		font-size: 1em;
		margin-bottom:$vert-space*0.75;
		background: none;
		padding:0;
		color:$primary;
	}
	
	.card-tag {
		display: none;
	}
	
	.card-display-date {
		display:inline;
		width: auto;
		
	}
	
	.card-location {
    display: inline-block;
		font-size: 1em;
		margin-bottom:$vert-space*0.75;
		&:before {
			content: "|";
    	display: inline;
    	font-size: 22px;
    	height: 22px;
    	margin: 0 5px;
    	vertical-align: middle;
		}
	}

  .card-summary {
    color:$primary;
    font-size:1.25em;
    font-weight:bold;
    line-height:1.3;
  }
	
	&.node-organisation {
		.card-logo {
			text-align: center;
			background-color: transparent;
			margin-bottom:1rem;
			
			@media(min-width:$narrow) {
				float:right;
				margin-left:1.25rem;
				max-width:calc(50% - 0.625rem);
			}
		}
	}	
	.card-link {
		margin-top: 20px;
	}	
}