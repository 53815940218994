.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
	// min-height: 95px;
	@include media($narrow) {
		min-height: 120px;
	}
		&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}
	//
	// /* Change .header-outer-wrapper to
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-inner-wrapper {
		position:relative;
		@include outer-container($site-max-width);
	}
	.header-inner-wrapper {
		height:130px;
		@media(min-width: 390px) {
			height:110px;
		}
		@media(min-width: 880px) {
			height:140px;
		}
	}
	.main-nav-wrapper {
		height:70px;
		margin-top: 10px;
		position:relative;
		@include media($narrow) {
			margin-top: 0;
		}
		@media(min-width: 880px) {
			height:46px;
		}
	}
	.site-branding {
		// margin-top:2.5rem;
		margin-left:50px;
		position: relative;
		@include media(880px) {
			top:0;
		}
		.site-title {
			position: absolute;
			left: -35px;
	    top: 75px;
			z-index: 4;
				@media(min-width: 540px) {
				min-height: 15px;
				// top:90px;
				left: 0;
				// top: 30px;
			}
			@media(min-width: 880px) {
				top:25px;
			}
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:220px;
				height:88px;
				@media(min-width: 540px) {
					width:280px;
					height:90px;
				}
				@media(min-width: 880px) {
					width:320px;
					height:120px;
				}
			}
		}
		.site-slogan {
			display: none;
		}
	}

	//CUSTOM HEADER LOGOS
	.wrapper-region-header {
		.header-logos-wrapper {
			height:75px;
			width: 100%;
			padding:10px;
			display: inline-block;
			// text-align: center;
			@include media($narrow){
				float: right;
				margin-left: calc(50% - 184px);
				width: 360px;
				text-align: left;
			}
			@media(min-width: 880px) {
				width: 210px;
				height: 109px;
				margin-left: 0;
				margin-top: 10px;
				padding: 0;
				display: flex;
				flex-direction: column-reverse;
			}
			.logos {
				display: inline-block;
				float: right;
				.logo {
					height: 50px;
					width: 50px;
					text-indent: -9999px;
					display: inline-block;
					text-align: center;
					@include media($narrow){
						height: 75px;
						width: 75px;
					}
					&.edctp {
						background: $logo-edctp no-repeat 50% 50% / 50px 50px;
						margin-right: 10px;
						@include media($narrow){
							background-size: 75px 75px;
						}
					}
					&.eu {
						background: $logo-eu no-repeat 50% 0% / 50px;
						@include media($narrow){
							background-size: 75px 50px;
						}
					}
				}
			}
			.text-wrapper {
				font: 0.555em/120% arial;
				color:$secondary;
				display: inline-block;
				width: calc(50% - 20px);
				@include media($narrow) {
					width: 145px;
					margin-right: 20px;
				}
				@media(min-width: 880px) {
					margin-right: 0;
					margin-top: 10px;
					width: auto;
				}
			}
		}
	}

.main-navigation {
		position: relative;
		margin-left: 130px;
    // width: calc(100% - 130px);
		@include outer-container($site-max-width);
		// max-width: unset;

		.block-menu-block {
			@media(min-width: 880px) {
				//margin-top: 56px;
				margin-right: $search-toggle-width-normal;
			}
			@media(min-width: 1150px) {
				margin-right: $search-toggle-width-wide;
				//margin-top: 51px;
			}
		}


	  .search-toggle-wrapper {
			position: absolute;
			top:0;
			right:$nav-button-width;
			display: block;
			@media(min-width: 880px) {
				right: 0;
			}
			.search-toggle {
				height: $search-toggle-height-mobile;
				width: $search-toggle-width-mobile;
				background: $search-icon-mobile;
				background-size: $search-icon-size-mobile;
				text-indent: -9999px;
				&:hover {
					background: $search-icon-hover;
					background-size: $search-icon-size-mobile;
				}
				&.active {
					background: $search-icon-active;
					background-size: $search-icon-size-mobile;
				}
				&.active:hover {
					background: $search-icon-active-hover;
					background-size: $search-icon-size-mobile;
				}
				@media(min-width: 880px) {
					height: $search-toggle-height-normal;
					width: $search-toggle-width-normal;
					background:$search-icon;
					background-size: $search-icon-size-normal;
					// border-left: 2px solid white;
					&:hover,
					&.active,
					&.active:hover {
						// background:$search-icon-hover;
						background-size: $search-icon-size-normal;

					}
				}
				@media(min-width: 1151px) {
					height: $search-toggle-height-wide;
					width: $search-toggle-width-wide;
					background:$search-icon;
					background-size: $search-icon-size-wide;

					&:hover,
					&.active,
					&.active:hover {
						// background:$search-icon-hover;
						background-size: $search-icon-size-wide;
					}
				}
			}
		}

		.block-search {
			position: absolute;
			display: block;
			right: 0;

			.form-actions {
		    float: left;
				input.form-submit {
					background: $search-icon-input;
					width:26px;
					height:26px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:20px;
				}
			}
			@media(max-width: 879px) {
				width:100%
			}
			#search-block-form--2 {
				display: none;
				position: absolute;
				right:0;
				top: 70px;
				background: $search-toggle-bg-active;
				z-index: 1;
				padding: 10px;
				height:60px;
				width:100%;
				max-width: 390px;
				h2 {display: none;}
				@media(min-width: 460px) {
					// top: 70px;
					right: $nav-button-width;
				}
				@media(min-width: 880px) {
					top: 0;
					right: 0;
					width: auto;
				}
				.form-item {
					margin:0;
					@media(max-width: 879px) {
						width:100%
					}
				}
				.form-item-search-block-form input.form-text {
					width:320px;
					height: 40px;
					background: white;
					color: $primary;
					@media(max-width: 879px) {
						width:100%
					}
					&::placeholder {
						color:$primary;
					}
				}
				.form-wrapper {
					display:block;
					@include media(880px) {
						display:block;
					}
				}
				input[type="submit"] {
					background:$search-icon-input;
				}
				@media(min-width: 880px) {
					margin-top: $search-toggle-height-normal;
				}
				@media(min-width: 1151px) {
					margin-top: $search-toggle-height-wide;
				}
			}
		}

	}
	.block-search {
		display:none;
		.form-actions {
			float:left;
			margin-top:0.75rem;
			@include media(880px) {
				margin-bottom:5rem;
			}
			input.form-submit {
				background: transparent $search-indigo no-repeat scroll 0 0;
				background-size:14px;
				width:0.875rem;
				height:0.875rem;
				text-indent:-999em;
				overflow:hidden;
				padding:0;
				position:absolute;
				right:1.25rem;
				top:1.25rem;
			}
		}
	}
	.block-menu {
		float:right;
		margin-top:51px;
		margin-right:0.5rem;
		@include media(880px) {
			margin-right:1.5rem;
			margin-bottom:5rem;
		}
		li {
			display:inline-block;
			margin:0 0.75rem 0 0;
		}

		a {
			font:$header-menu-font;
			color:$header-menu-link-color;

			&:hover {
				color:$header-menu-link-hover;
			}
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}
